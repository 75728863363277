body {
  font-family: "Open Sans", sans-serif;
  max-width: 100%;
  overflow-x: hidden;
}

.go-to-top {
  z-index: 99999;
  position: fixed;
  right: 20px;
  bottom: 20px;
  cursor: pointer;
}

a.defly-mp-btn.nav-link ::before {
  background: red;
  content: "NEW";
  font-weight: bolder;
  position: absolute;
  top: 29px;
  font-size: 12px;
  padding: 4px 10px;
  clip-path: polygon(
    0% 0%,
    100% 0%,
    100% 85%,
    75% 85%,
    75% 100%,
    50% 85%,
    0% 85%
  );
  font-family: "Quicksand";
  z-index: 9999999999999;
  color: white;
  padding-top: 1px;
}

@media screen and (max-width: 1023px) {
  a.defly-mp-btn.nav-link ::before {
    display: none !important;
  }
}
.nav-link {
  text-align: center;
}
