.coin-tokken-inner-container {
  max-width: 500px;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 15px;
  border: 1px solid white;
  border-radius: 10px;
  font-family: "Quicksand", sans-serif;
  font-style: normal;
  font-weight: 100;
  font-size: 16px;
  line-height: 20px;
}

.coin-tokken-inner-container > span > button {
  background-color: transparent;
  border: none;
  outline: none;
}

.copy-text {
  color: #fcc016;
  font-weight: "bold";
  cursor: "pointer";
}

@media screen and (max-width: 400px) {
  .coin-tokken-inner-container {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 4px;
    border: 1px solid white;
    border-radius: 10px;
    font-family: "Quicksand", sans-serif;
    font-style: normal;
    font-weight: 100;
    font-size: 12px;
    line-height: 14px;
  }
}
