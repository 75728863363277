@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap");

/* .roadmap-wrapper {
  display: flex;
  gap: 10px;
} */

.road-map2 {
  overflow-x: hidden;
  max-width: 500px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
.roadmap-bg {
  max-width: 500px;
  height: auto;
  margin: 0 auto;
  padding: 20px 0px 20px 20px;
  position: relative;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("./images/maskgroup1.png");
  /* background-color: rgba(50, 70, 80, 0.7);
  background-blend-mode: soft-light; */
  background-repeat: no-repeat;
  object-fit: cover;
  background-position: right;
}
.roadmap-bg2 {
  max-width: 500px;
  height: auto;
  padding: 20px 0px 20px 20px;
  margin: 0 auto;
  position: relative;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("./images/maskgroup1.png");
  background-repeat: no-repeat;
  background-position: right;
  object-fit: cover;
}
.roadmap-bg3 {
  max-width: 500px;
  height: auto;
  padding: 20px 0px 20px 20px;
  position: relative;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("./images/maskgroup1.png");
  background-repeat: no-repeat;
  background-position: right;
  object-fit: cover;
}
.vertical-img {
  z-index: 1111;
  margin-right: -40px;
}
.liststyle {
  list-style-image: url("./images/dot.png");
}
.liststyle li {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  /* or 164% */

  text-transform: capitalize;

  color: #c7c1c1;
}
.heading {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16.5493px;
  line-height: 23px;
  /* identical to box height */

  text-transform: uppercase;

  color: #ffffff;
}
.horizontalline {
  margin-left: 8px;
  margin-top: -15px;
}
@media screen and (max-width: 600px) {
  .liststyle li {
    font-weight: 300;
    font-size: 9px;
    line-height: 21px;
  }
}

@media screen and (max-width: 768px) {
  .roadmap-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
