@import url("https://fonts.googleapis.com/css2?family=Potta+One&display=swap");
.main-container {
  width: 80%;
  overflow-x: hidden;
  margin: 100px auto;
}
.marketplace-content .img > img {
  width: 210;
  height: 190px;
  /* width: 220;
  height: 220px;
  background-image: url("./images/Component12.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-size: 210px 190px; */
  cursor: pointer;
}
.marketplace-content .img:hover {
  background-image: url("./images/giphy.gif");
  mix-blend-mode: lighten;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.marketplace-content .imgone > img {
  width: 210px;
  height: 190px;
  /* background-image: url("./images/Component13.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-size: 210px 190px; */
  cursor: pointer;
}
.marketplace-content .imgone:hover {
  background-image: url("./images/giphy.gif");
  mix-blend-mode: lighten;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.marketplace-content .imgtwo > img {
  width: 210;
  height: 190px;
  /* background-image: url("./images/Component14.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-size: 210px 190px; */
  cursor: pointer;
}
.marketplace-content .imgtwo:hover {
  background-image: url("./images/giphy.gif");
  mix-blend-mode: lighten;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.marketplace-content .imgthree > img {
  width: 210;
  height: 190px;
  /* background-image: url("./images/Component15.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-size: 210px 190px; */
  cursor: pointer;
}
.marketplace-content .imgthree:hover {
  background-image: url("./images/giphy.gif");
  mix-blend-mode: lighten;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

/* .com12 {
  background: url("./images/giphy1.png");
  mix-blend-mode: lighten;
} */
.bottom-img {
  margin-top: 150px;
}
.marketplace-heading {
  font-family: "Potta One", cursive;
  font-style: normal;
  font-weight: 400;
  font-size: 18.7436px;
  line-height: 27px;
  text-align: center;
  text-transform: uppercase;
  align-self: center;
  margin-bottom: 20px;

  color: #ffffff;
}

@media screen and (max-width: 768px) {
  .bottom-img {
    margin-top: 20px;
  }
}
