.card-content {
  width: 88%;
  margin: 0 auto;
}

.card-content > :nth-child(2) {
  transform: scale(1.1);
}

.blog-card {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  width: 300px;
  border: 1px solid #fcc016;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card2 {
  background-color: #e4a101;
  border: 2px solid white;
}

@media screen and (max-width: 992px) {
  .card-content > :nth-child(2) {
    transform: scale(1);
  }
}

@media screen and (max-width: 600px) {
  .card-content {
    width: 80%;

    /* margin-left: 40px; */
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
  }
  .blog-card {
    width: 250px;
    gap: 10px;
    margin-left: 0px;
  }

  .card-content > :nth-child(2) {
    transform: scale(1);
  }
}
