.outer-container {
  margin-top: 100px;
  margin-bottom: 100px;
}
.inner-container {
  position: relative;
  text-align: center;
}
.inner-container > h2 {
  position: absolute;
  top: 35px;
  left: 45%;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 41px;
  /* identical to box height */

  text-transform: uppercase;

  color: #fcc016;
}
.audit-img {
  display: flex;
  justify-content: space-between;
  width: 70%;
}
.audit-img > img {
  width: 300.09px;
  height: 137.91px;
}

@media screen and (max-width: 480px) {
  .inner-container > img {
    width: 90%;
    height: 100px;
  }
  .inner-container > h2 {
    position: absolute;
    top: 35px;
    left: 35%;
  }
  .audit-img {
    margin-top: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .audit-img > a > img {
    width: 100%;
    height: 137.91px;
  }
}
@media screen and (max-width: 768px) {
  .audit-img {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    width: auto;
  }

  .audit-img > a > img {
    width: 100%;
    height: 137.91px;
  }
}
